<template>
  <div class="d-flex align-items-center justify-content-center vh-50">
    <!-- Konten lainnya -->
    <b-link class="brand-logo">
      <!-- <vuexy-logo /> -->
      <h2 class="brand-text text-primary ml-1">
        Proses Verifikasi
      </h2>
    </b-link>
  </div>
</template>

<script>
/* eslint-disable global-require */
// eslint-disable-next-line import/no-cycle
import store from '@/store/index'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import { BLink } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

// auth

export default {
  components: {
    BLink,
    // VuexyLogo,
  },
  metaInfo() {
    return {
      title: 'Kanal Dagang',
      titleTemplate: '%s - Masuk',
    }
  },
  mounted() {
    if (this.$route.query.code) {
      store.dispatch('auth/activationUser', { code: this.$route.query.code })
    }
  },
  setup() {
    const { appLogoImage } = $themeConfig.app
    return {
      appLogoImage,
    }
  },

}
</script>

<style lang="scss">
// @import '@core/scss/vue/pages/page-auth.scss';
</style>
